import React, { useState, useEffect, useRef } from "react"
import { navigate } from "gatsby"
import { Button, Offcanvas, Form, Modal } from "react-bootstrap"
import InputGroup from "react-bootstrap/InputGroup"
import InfoScrollbar from "../UserInfoScroll"
import {
  createStaffToPay,
  sendPaySuccessEmail,
  getWindowsLocation,
  getDeviceCode,
} from "../../api/app.ts"
import { EventUtil } from "../../utils/tools.ts"

const TryFree = props => {
  const [show, setShow] = useState(false)

  const { setShowScroll, priceIds, cbUrl } = props

  const handleClose = () => {
    setShowScroll(true)
    setShow(false)
  }
  const handleShow = () => {
    setShowScroll(false)
    setShow(true)
  }

  const [validated, setValidated] = useState(false)

  const [emailSub, setEmailSub] = useState(true)

  const [email, setEmail] = useState("")
  const [isEmailValid, setIsEmailValid] = useState(true)

  const [priceId, setPriceId] = useState(priceIds.annually)

  const [showPop, setShowPop] = useState(false)
  const handlePopClose = () => setShowPop(false)

  const [popText, setPopText] = useState("")
  const [errPop, setErrPop] = useState(false)

  const [isInputDisplay, setIsInputDisplay] = useState({ show: true })
  const [isInputFocus, setIsInputFocus] = useState(false)
  const timerFlag = useRef(null)

  const [loading, setLoading] = useState(false)

  const handleSubmitEmail = async (needPop = true) => {
    const reg = /^[0-9a-zA-Z_.-]+[@][0-9a-zA-Z_.-]+([.][a-zA-Z]+){1,2}$/
    const isValidEmail = reg.test(email)
    setIsEmailValid(isValidEmail)
    if (isValidEmail) {
      setLoading(true)
      if (typeof window !== "undefined") {
        const path = getWindowsLocation()
        window.subscribeData = { email, emailSub, path }
        sessionStorage.setItem(
          "TEMP_LANDING",
          JSON.stringify({ email, emailSub, path })
        )
        const params = {
          path,
          emailSub,
          email,
          priceId,
          origin: getDeviceCode(),
          autoEmail:true
        }
        const { success, data } = await createStaffToPay(params)
        // if (success) {
        //   sendPaySuccessEmail({ userId: data.userId })
        // }
        const nextPageAddress=window.location.origin
        // 需要手动更换地址
        window.location.href = nextPageAddress
        // navigate("/")
      }
    }
  }

  const handleResend = () => {
    setShowPop(false)
    handleSubmitEmail(false)
  }

  const handleShowInput = () => {
    setIsInputDisplay(false)
    if (timerFlag.current) {
      clearTimeout(timerFlag.current)
    }
    timerFlag.current = setTimeout(() => {
      setIsInputDisplay(true)
    }, 400)
  }

  const handleScroll = e => {
    if (
      [
        "form-control",
        "btn btn-primary",
        "tryFreeFour",
        "form-check-label",
        "form-check-input",
        "is-invalid",
        "form-control is-invalid",
        "invalid-feedback",
        "modal-header",
        "top",
        "bottom",
        "popFooter modal-footer",
        "modal-body",
        "poplogo",
        "btn-close",
        "close",
        "resend btn btn-primary",
      ].includes(e.target.className)
    ) {
      return
    }
    setIsInputFocus(false)
    handleShowInput()
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      EventUtil.listenTouchDirection(
        document,
        false,
        handleScroll,
        false,
        handleScroll,
        false
      )
    }
    return () => {
      EventUtil.removeHandler(document, "touchstart", null)
      EventUtil.removeHandler(document, "touchend", null)
      EventUtil.removeHandler(document, "touchmove", null)
    }
  }, [])

  const handleOnFocus = () => {
    setLoading(false)
    setIsInputFocus(true)
  }
  return (
    <>
      <section
        className="tryFreeFour"
        id="tryFreeFour"
        style={{
          bottom: isInputDisplay ? (isInputFocus ? "40vh" : 0) : "-108px",
          // top: isInputDisplay ? (isInputFocus ? "60px" : "") : "",
          borderRadius: isInputFocus ? "8px" : "0px",
          opacity: isInputDisplay ? 1 : "0.5",
          // height: isInputFocus ? '60vh' : '108px'
        }}
      >
        <InputGroup className="fourInputGroup" hasValidation>
          <Form.Control
            required
            isInvalid={!isEmailValid}
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            onFocus={handleOnFocus}
            onBlur={() => setIsInputFocus(false)}
            id="fourEmailInput"
          />
          <Button id="fourSendEmail" onClick={handleSubmitEmail}>
            <img
              src="/assets/images/loading_white.png"
              className="loading_icon"
              style={{ display: loading ? "block" : "none" }}
            />
            Send Free Trial
          </Button>
          <Form.Control.Feedback type="invalid">
            Please enter a valid email address
          </Form.Control.Feedback>
        </InputGroup>
        <Form.Check
          className="tryFreeTips"
          type="checkbox"
          label="Get Daily Online Business Tips for Free."
          defaultChecked
          onChange={e => setEmailSub(e.target.checked)}
        />
      </section>

      <Modal
        show={showPop}
        onHide={handlePopClose}
        centered
        className="fourPopContent"
      >
        <Modal.Header closeButton>
          <img src="/assets/icons/logo.png" className="poplogo" />
        </Modal.Header>
        <Modal.Body>
          <p className="top">Free trial invitation has been sent</p>
          <p className="bottom">
            Check your email and activate your Cubo online office now.
          </p>
        </Modal.Body>
        <Modal.Footer className="popFooter">
          <div className="close" onClick={handlePopClose}>
            Got it
          </div>
          <Button className="resend" onClick={handleResend}>
            Resend
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default TryFree
